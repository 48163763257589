import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { Player } from "@lottiefiles/react-lottie-player"
import Seo from "../components/seo"

const Thankyou = () => (
    <Layout>
        <Seo title="Successo"></Seo>
        <div className="mt-24">
            <Player src="https://assets7.lottiefiles.com/packages/lf20_odpfgoto.json" autoplay loop style={{ height: '300px'}}></Player>
        </div>
        <h1 className="text-center">Grazie per averci contattato.</h1>
        <span className="text-xl text-center block">Il nostro staff ti risponderà il prima possibile.</span>

        <div className="mt-12 text-center">
            <Link className="btn btn-primary" to="/">Ritorna alla home</Link>
        </div>
    </Layout>
)

export default Thankyou